import React, { useEffect } from "react";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";

import "./styles/about.css";

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<div className="page-content">
				<NavBar active="about" />
				<div className="content-wrapper">
					<div className="about-logo-container">
						<div className="about-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="about-container">
						<div className="about-main">
							<div className="about-right-side">
								<div className="title about-title">
									Get to know me!
								</div>
								<div className="subtitle about-subtitle">
									Hello, I'm Chiran Gurung. I'm a
									<span className="highlight">
										Frontend Focused Web Developer
									</span>
									building and managing the Front-end of
									Websites and Web Applications that leads to
									the success of the overall product. I've
									worked on a variety of projects over the
									years and I'm proud of the progress I've
									made.
									<br />
									<br />
									My expertise lies on the frontend, primarily
									via
									<span className="highlight">React </span>and
									<span className="highlight">
										JavaSript.
									</span>
									I value writing human-readable code, and
									building interfaces that equally weigh
									beautiful design with accessibility for all.
									I have also experience in backend
									development, including
									<span className="highlight">
										Node.js/Express/MongoDB, C#/.Net
										Core/MSSQL.
									</span>
									<br />
									<br />I completed an Advanced Diploma in
									<span className="highlight">
										IT Innovation and Design from Conestoga
										College with 3.77/4 GPA
									</span>
									, where I got to explore the knowledges and
									disciplines beyond just coding, such as
									Unity development, Blender for 3D modeling,
									and learning about different levels of
									<span className="highlight">WCAG 2.0</span>
									accessibility standards as well as emerging
									technologies like
									<span className="highlight">
										machine learning, cloud computing,
										blockchain, web 3.0
									</span>
									and much more. This has given me a
									well-rounded skill set and understanding of
									inclusive design.
									<br />
									<br />
									Throughout my journey, my education and
									experience have prepared me to be a
									versatile and capable software developer,
									with the technical skills as well as the
									broader perspective to create high-quality,
									user-friendly applications.
								</div>
							</div>

							<div className="about-left-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<img
											src="../about-image.jpg"
											alt="about"
											className="about-image"
										/>
									</div>
								</div>

								<div className="about-socials">
									<Socials />
								</div>
							</div>
						</div>
						<br />
						<br />
						<br />

						<div className="about-socials-mobile">
							<Socials />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default About;
