const INFO = {
	main: {
		title: "Chiran Gurung",
		name: "Chiran Gurung",
		email: "chirangrg65@gmail.com",
		logo: "../nft-logo.png",
	},

	socials: {
		github: "https://github.com/cgrgx",
		linkedin: "https://www.linkedin.com/in/chirangurung/",
		leetcode: "https://leetcode.com/u/ChiranGurung/",
		codesandbox: "https://codesandbox.io/u/chirangrg20",
		freecodecamp:
			"https://www.freecodecamp.org/fcc5f29c41b-b34a-4856-abb9-961dc6890979",
		// instagram: "https://instagram.com/",
		// twitter: "https://twitter.com/eddieodev",
		// twitter: "https://twitter.com/",
		// stackoverflow: "https://stackoverflow.com/",
		// facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Chiran Gurung.",
		description:
			"I am a front end developer based in Waterloo, Canada. I specialize in React and JavaScript. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "Get to know me!",
		description:
			"I'm a Frontend Focused Web Developer building and managing the Front-end of Websites and Web Applications that leads to the success of the overall product. I've worked on a variety of projects over the years and I'm proud of the progress I've made. Check out some of my work in the Projects section. I'm open to Job opportunities where I can contribute, learn and grow. If you have a good opportunity that matches my skills and experience then don't hesitate to contact me.",
	},
	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Sleepy Goose Motel - Motel Management App",
			description: `A web-based platform for internal motel staffs to manage everything related to motels. It is a full-stack application that allows users to manage their bookings, guests, services and rooms based on their roles. The project is part of the Capstone Project in College.`,

			// Exceeding four logos, leads to a poor mobile UX
			logos: [
				"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
				"../tool-icons/react.png",
				"../tool-icons/react-query-icon_.png",
				"../tool-icons/tailwind-css.png",
				"../tool-icons/supabase_.png",
			],
			linkText1: "GitHub",
			link1: "https://github.com/cgrgx/Group4-Capstone-Motel-Management-App",
			linkText2: "Live",
			link2: "https://group4-capstone-motel-management-app.vercel.app/",
		},

		{
			title: "React Pizza - Pizza Ordering App",
			description: `A pizza ordering app to make ordering pizza as easy as possible. The app optimizes the pizza ordering experience with features like a dynamic menu, seamless cart system, GPS-enabled delivery tracking, and priority ordering to streamline the process for customers. It focuses on providing a user-centric, efficient pizza ordering solution.`,
			logos: [
				"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
				"../tool-icons/react.png",
				"../tool-icons/redux.png",
				"../tool-icons/geolocation.png",
				"../tool-icons/tailwind-css.png",
			],
			linkText1: "GitHub",
			link1: "https://github.com/cgrgx/react-pizza",
			linkText2: "Live",
			link2: "https://react-pizza-alphaa.vercel.app/",
		},

		{
			title: "WorldWise - Travel Tracking App",
			description: `A traval tracking app that allows users to dynamiclly track their travel locations on a world map, never forgetting their wonderful experiences. It keeps a comprehensive record of one's adventures in an engaging, user-friendly manner.`,
			logos: [
				"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
				"../tool-icons/react.png",
				"../tool-icons/contextapi.png",
				"../tool-icons/geolocation.png",
				"../tool-icons/cssmodules_.png",
			],
			linkText1: "GitHub",
			link1: "https://github.com/cgrgx/worldwise",
			linkText2: "Live",
			link2: "https://worldwise-alpha.vercel.app/",
		},
	],
};

export default INFO;
